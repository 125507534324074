.react-datepicker__day {
    &--keyboard-selected {
        background: none !important;
        font-weight: normal !important;
    }

    &--today {
        font-weight: normal !important;
    }

}