.columns-container-2, .columns-container-3 {
    display: grid;
}

.columns-container-2 {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
}

.columns-container-3 {
    grid-template-columns: 1fr 1fr 1fr;
}
  
// Smaller than MD screen size
@media #{$usb-max-md} {
    .columns-container-2, .columns-container-3 {
        grid-template-columns: 1fr;
    }
}

// Smaller than SM screen size - Phone
@media #{$usb-max-sm} {
    .columns-container-2, .columns-container-3 {
        grid-template-columns: 1fr;
    }
}

@media screen and (min-width: 360px) and (max-width: $token-breakpoint-small) {
    .columns-container-2, .columns-container-3 {
        grid-template-columns: 1fr;
    }
}

@media screen and (min-width: $usb-screen-600) and (max-width: 898px) {
    .columns-container-2, .columns-container-3 {
        grid-template-columns: 1fr;
    }
}

@media (width: $usb-screen-1024) {
    .columns-container-2, .columns-container-3 {
        grid-template-columns: 1fr;
    }
}