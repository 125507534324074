.usb-notification {
  margin-bottom: $token-spacing-4;

  .single-notification--text {
    padding-bottom: $token-spacing-0 !important;
  }

  > .error .multiple-notification--text {
    display: unset;
  }
  a {
    text-decoration: underline !important;
  }

  > .warning ul {
    margin: $token-spacing-1 $token-spacing-0 $token-spacing-0 $token-spacing-4 !important;
  }

  > .confirmation .multiple-notification--text,
  > .confirmation .single-notification--text,
  > .error .multiple-notification--text,
  > .error .single-notification--text,
  > .information .multiple-notification--text,
  > .information .single-notification--text,
  > .warning .multiple-notification--text,
  > .warning .single-notification--text {
    font-size: 0.875rem !important;
    font-weight: 400 !important;
  }
}
