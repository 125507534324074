.sc-notice {
    position: relative;

    display: flex;
    flex-direction: column;

    margin: $token-spacing-8 $token-spacing-0;
    padding: $token-spacing-4 $token-spacing-4 $token-spacing-4px;

    border-radius: $token-spacing-4px;

    box-shadow: unquote($token-shadow-sm);

    div {
        display: flex;

        margin: $token-spacing-0 $token-spacing-0 $token-spacing-12px;

        color: $token-text-color-primary;

        p {
            font-size: $token-font-size-14px;
            line-height: $token-line-height-21px;
        }

        &.notice-title {
            flex-direction: row;
            align-items: center;

            .notice-icon {
                svg {
                    vertical-align: text-bottom;
                }
            }

            p {
                margin: $token-spacing-0 $token-spacing-0 $token-spacing-0 $token-spacing-12px;

                font-weight: $token-font-weight-med;

                text-transform: capitalize;
            }
        }

        &.notice-content {
            flex-direction: column;

            p {
                margin: $token-spacing-0 $token-spacing-0 $token-spacing-0 $token-spacing-6;

                font-weight: $token-font-weight-reg;
            }
        }

        a {
            color: $token-notice-link-color !important;
            text-decoration: none !important;

            &:hover {
                text-decoration: underline !important;
            }
        }
    }

    ul {
        list-style: disc;
        padding-inline-start: $token-spacing-12;
        margin-block-start: $token-spacing-12px;
        margin-block-end: $token-spacing-2;
    }

    &.info,
    &.note,
    &.tip {
        border-top: solid $token-spacing-4px $notification-information-border-top;
        border-right: solid $token-border-width-default $token-outline-neutral;
        border-bottom: solid $token-border-width-default $token-outline-neutral;
        border-left: solid $token-border-width-default $token-outline-neutral;
        background-color: $token-background-neutral;
    }

    &.warning {
        border-top: solid $token-spacing-4px $notification-error-border-top;
        border-right: solid $token-border-width-default $token-outline-error;
        border-bottom: solid $token-border-width-default $token-outline-error;
        border-left: solid $token-border-width-default $token-outline-error;
        background-color: $token-background-color-error;
    }
    
    &.important {
        border-top: solid $token-spacing-4px $notification-warning-border-top;
        border-right: solid $token-border-width-default $token-outline-warning;
        border-bottom: solid $token-border-width-default $token-outline-warning;
        border-left: solid $token-border-width-default $token-outline-warning;
        background-color: $token-background-warning;
    }
}
