.usb-modal-v2 {
  &--dialog .modal-button-group .usb-button.button--default {
    margin: (-$token-spacing-4) $token-spacing-0;

    &:last-child {
      margin-right: $token-spacing-5;
    }
  }

  &--footer .usb-button-group {
    display: flex;
  }

  /*small screen in mobile view*/
  @media #{$usb-max-md} {
    &--footer .usb-button-group {
      display: grid;
    }

    &--dialog .modal-button-group .usb-button.button--default {
      margin: $token-spacing-3 $token-spacing-0 !important;
    }

    &--dialog .usb-button.button--default {
      margin-bottom: $token-spacing-4;
    }
  }
}

@media screen and (min-width: 42rem) {
  .usb-modal-v2--body {
    max-height: none !important;
  }
}
