.error-toast {
  &-icon {
    background-color: $toast-error-color;
  }
}

.success-toast {
  &-icon {
    background-color: $toast-success-color;
  }
}

.success-toast,
.error-toast,
.warning-toast {
  height: $token-spacing-12;
  max-width: $token-spacing-500px !important;

  @media #{$usb-max-md} {
    max-width: $token-spacing-350px !important;
  }

  width: 100%;

  .success-toast-container,
  .warn-toast-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $token-spacing-3 $token-spacing-4;
    width: inherit;

    .success-toast-content,
    .warn-toast-content {
      display: flex;
      align-items: center;
    }
  }

  .success-toast-icon,
  .warn-toast-icon {
    color: $icon-light-fill-color;

    &:hover {
      cursor: pointer;
    }
  }
}