.hljs {
  display: block;
  overflow-x: auto;
  background: $hljs-background !important;
  color: $hljs-color;
  padding: $token-spacing-2;
}

.hljs-comment {
  color: $hljs-comment-color;
}

.hljs-subst {
  color: $hljs-subst-color;
}

.hljs-doctag,
.hljs-name {
  color: $hljs-doctag-name-color;
}

.hljs-keyword,
.hljs-attribute {
  color: $hljs-keyword-attribute-color;
}

.hljs-selector-tag,
.hljs-tag {
  color: $hljs-selector-tag-color;
}

.hljs-attr {
  color: $hljs-attr-color;
}

.hljs-built_in,
.hljs-builtin-name,
.hljs-bullet,
.hljs-code,
.hljs-addition {
  color: $hljs-built-bullet-code-addition-color;
}

.hljs-literal {
    color: $hljs-literal-color !important;
}

.hljs-regexp,
.hljs-symbol,
.hljs-variable,
.hljs-template-variable,
.hljs-link,
.hljs-selector-attr,
.hljs-selector-pseudo {
  color: $hljs-regexp-symbol-template-variable-link-selector-attr-pseudo-color;
}

.hljs-type,
.hljs-string,
.hljs-selector-id,
.hljs-selector-class,
.hljs-quote,
.hljs-template-tag,
.hljs-deletion {
  color: $hljs-type-string-selector-id-class-quote-template-tag-deletion-color;
}

.hljs-title,
.hljs-section {
  color: $hljs-title-section-color;
}

.hljs-meta {
  color: $hljs-meta-color;
}

.hljs-meta-keyword {
  color: $hljs-meta-keyword-color;
}

.hljs-params {
  color: $hljs-params-color;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-function {
  color: $hljs-function-color;
}

.hljs-number {
  color: $hljs-number-color;
}

.sc-iJnaPW blockquote ul li {
  color: $token-text-color-primary;
}

.sc-jcMfQk a {
  color: $token-background-inverse-primary !important;
}

.sc-GKYbw code a {
  color: $code-anchor-text-blue !important;
}
