.page-heading-list,
.section-heading-list {
    ul {
        margin: $token-spacing-4 $token-spacing-0;
        padding-left: $token-spacing-40px;

        li {
            list-style: disc !important;
        }
    }
}