.code-example {
    display: flex;
    overflow-x: auto;
    padding: $token-spacing-2;
    
    code {
        white-space: pre-wrap !important;
        color: $codeBlock-code-color;
        background-color: $codeBlock-code-background-color;
    }

    .inline {
        justify-content: flex-start;
        padding: $token-spacing-0 $token-spacing-2 $token-spacing-0;

        li.sub {
            margin: $token-spacing-4 $token-spacing-4 $token-spacing-0 $token-spacing-0;
            padding: $token-spacing-0;

            font-size: $token-font-size-xl;
            font-weight: $token-font-weight-reg;
            line-height: $token-line-height-32px;
        }
    }

    .code-block-copy {
        padding:  $token-spacing-2 $token-spacing-2 $token-spacing-1 $token-spacing-2;
        margin: $token-spacing-0 (-$token-spacing-4);
        
        border: none;
        border-radius: $token-spacing-0 $token-spacing-0 $token-spacing-4 $token-spacing-4;

        cursor: pointer;

        background: transparent;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        svg {
            width: $token-spacing-6;
            height: $token-spacing-6;
        }
    }
}