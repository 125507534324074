.sc-phonecard,.sc-email{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: $token-spacing-4;
    padding: $token-spacing-4;
    padding: $token-spacing-4 $token-spacing-4 $token-spacing-4px;
    border-radius: $token-spacing-2;
    border: solid $token-border-width-default $token-border-color-grey;
    background-color: $token-background-primary;
    div {
        display: flex;
        margin: 0 0 $token-spacing-12px;
        color: $token-text-color-primary;
        p {
            font-size: $token-font-size-14px;
            line-height: $token-line-height-loose;
        }
        &.phone-card-title,&.email-card-title {
            flex-direction: row;
            align-items: center;
            .phone-card-icon,.email-card-icon {
                svg {
                    vertical-align: text-bottom;
                }
            }
            p {
                margin: 0 0 0 $token-spacing-12px;
                font-weight:$token-font-weight-med;
                text-transform: capitalize;
                font-size: $token-font-size-lg;
            }
        }

        &.phone-card-content ,&.email-card-content{
            flex-direction: column;

            p {
                margin: 0 0 0 0;
                font-weight: $token-font-weight-reg;
                font-size: $token-font-size-base;
            }
        }

        a {
            color: $token-color-neutral-msg !important;
            text-decoration: underline !important;

            &:hover {
                text-decoration: underline !important;
            }
        }
    }

    ul {
        list-style: disc;
        padding-inline-start: $token-spacing-12;
        margin-block-start: $token-spacing-12px;
        margin-block-end: $token-spacing-2;
    }
}

@media #{$token-screen-large} {
    .sc-phonecard,.sc-email {
        width: $token-spacing-352px;
    }
}
     