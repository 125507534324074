@import '@/styles/theme/variables.scss';

.sessionTimeOutModal {
    z-index: 9999 !important;

    h1 {
        color: $modal-title-text-color !important;
        font-weight: $token-font-weight-med;
        margin-top: $token-spacing-0;
    }


    .sessionTimeOutForm {
        overflow: auto !important;
    }
    .logoutButton {
        margin-top: -1rem !important;
    }
    .continueSessionBtn {
        margin-bottom: -1rem !important;
    }
}