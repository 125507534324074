
.table-sort__container {
  // display: block;
  .table-sort-header {
    // bgColor can be $token-text-color-brand-primary or $token-text-color-tertiary or white, textColor can be white or $grey80, selectedColor can be brand-interaction or white
    @include headerBgColor(white, $token-color-grey-80, $token-color-brand-interaction);
    margin: $token-spacing-0;

    &.left-aligned {
      text-align: start;
    }
    &.right-aligned {
      text-align: end;
    }
  }
  @media #{$token-screen-small} {
    &.flex {
      display:flex;
      flex-direction:row;
      flex-wrap:none;
      align-items:center;
      .usb-button, .table-sort-header {
        width:auto;
        flex: 1 1 auto;
      }
    }
    width:100%;
  }
  .usb-button {
    width: 100%;
    margin:$token-spacing-0;
    padding:$token-spacing-0;
    font-size: $token-font-size-base;
    font-weight: $token-font-weight-med;
    margin:0px;
    padding:0px;
    display:flex;
    flex-direction:row-reverse;
    justify-content: space-between;
    padding-top: 100%;
    &.table-sort--ascending {
      .svg-icon {
        path:first-of-type{
          fill: $token-color-brand-interaction;
        }
        path:last-of-type{
          fill: $token-color-grey-80;
        }
      }
    }
  
    &.table-sort--descending {
      .svg-icon {
        path:last-of-type{
          fill: $token-color-brand-interaction;
        }
        path:first-of-type{
          fill: $token-color-grey-80;
        }
      }
    }

    &.right-aligned {
      flex-direction: row;
    }
    .usb-icon {
      color:inherit;
      transform:translateY(-2px);
      path {
        transition: fill 0.3s ease;
      }
    }

    &:hover, &:active {
      color:$token-color-brand-interaction !important;
      .usb-icon {
        color:inherit;
      }
    }
  }
  .usb-dropdown {
    width:100%;
    .dropdown__btn {
      width:100%;
      justify-content:flex-start;
      padding-left: $token-spacing-4;
      &-selection.selected {
        position:relative;
        top:0;left:0;
      }
      svg.dropdown-icon__arrow {
        position: relative;
      }
    }
  }
  .table-sort--status-empty {
    pointer-events: none;
    .dropdown__btn {
      .dropdown__btn-selection, svg {
        display: none;
      }
    }
  }
  .mobile-table-sort {
    float: right;
    padding: 0.6rem $token-spacing-0;
    .usb-dropdown.usb-dropdown__text .dropdown__btn{
      padding-right: 0rem;
    }
    .usb-dropdown .dropdown__list{
      min-width: 11rem;
    }
  }
}