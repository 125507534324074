.usb-tabs {
  background-color: $usbTabs-background-color;
  padding: $token-spacing-3 $token-spacing-3 $token-spacing-0 $token-spacing-3;
  border: $token-border-radius-4px solid $token-color-grey-30;
  border-radius: $token-spacing-4;

  .tabs__content {
    padding: 0 $token-spacing-0 $token-spacing-0 $token-spacing-0;

    .tabs__panel {
      display: flex;
      flex-direction: column;
    }
  }
}
