.usb--option-group--fieldset {
    border: 0;

    & .inline {
        display: flex;
        justify-content: center;
        gap: $token-spacing-3;
    }

    & .chip {
        display: flex;
        justify-content: left;
        gap: $token-spacing-3;
        & .usb--option--wrapper {
            flex-basis: unset;
            label {
                border-radius: $token-spacing-18px;
                background-color: $chips-selectable-background-color;
                border: $token-spacing-xs solid $chips-selectable-border-color;
                color: $chips-selectable-text-color;
                &:hover {
                    color: $token-text-color-interaction;
                }
            }
            input:checked + label {
                background-color: $chips_selectable-selected-background-color !important;
                color: $chips_selectable-selected-text-color !important;
            }
            input:focus + label {
                &::after {
                    border: unset;
                }
            }
        }
        & .card-select {
            background-color: unset;
            top: $token-spacing-2;
            right: $token-spacing-22;
            position: unset;
        }

        @media #{$usb-max-sm} {
            display: block;
        }
    }

    & .stack {
        display: flex;
        justify-content: left;
        gap: 0;

        & > .usb--option--wrapper {
            &:nth-child(1) {
                label {
                    border-left-width: $token-border-width-default;
                    border-top-left-radius: $token-spacing-1;
                    border-bottom-left-radius: $token-spacing-1;
                }
            }
            &:nth-last-child(1) {
                label {
                    border-top-right-radius: $token-spacing-1;
                    border-bottom-right-radius: $token-spacing-1;
                }
            }
            label {
                padding: $token-spacing-2;
                border-left-width: 0;
                border-radius: 0;
            }
            input:focus + label {
                &::after {
                    border: unset;
                }
            }
        }
    }

    & .column {
        display: flex;
        gap: $token-spacing-3;
        flex-wrap: wrap;
        & .usb--option--wrapper {
            flex-basis: 48%;
        }
    }

    & .card-with-label-and-image{
        & .usb--option--wrapper{
            flex-direction: row-reverse;
            
            input{
                opacity: 1;
                position: absolute;
                -webkit-appearance: auto;
                -moz-appearance: auto;
                appearance: auto;
                top: 50%;
                right: $token-spacing-5;
                z-index: 1;
                transform: translate(0, -50%);
                accent-color: $token-text-color-brand-primary;
                width: $token-spacing-5;
                height: $token-spacing-5;
            }
            input:focus + label {
                border: $button-utility-border-width solid $token-border-base;
                &::after {
                    display: none;
                }
            }
            input[type='radio']:checked + label{
                border: $token-border-width-large solid $token-text-color-brand-primary;
            }
            label{
                border: $button-utility-border-width solid $token-border-base;
                background-color: $token-background-primary;
                border-radius: $token-spacing-3;
                color: $token-text-color-secondary;
                img{
                    max-width: $token-spacing-13;
                    max-height: $token-spacing-13;
                    margin-right: $token-spacing-5;
                }

                & .label-text, .label-text-sub-label{
                    display: block;
                    text-align: left;
                    font-size: $token-font-size-xs;
                    line-height: $token-line-height-tight;
                }
                & .label-text{
                    font-size: $token-font-size-sm;
                    line-height: $checkbox-label-font-size;
                    font-weight: bold;
                }
            }
        }
    }
}

.usb--option-group--legend {
    font-family: $token-font-base;
    -webkit-font-smoothing: antialiased;
    font-size: $token-font-size-root;
    font-weight: $token-font-weight-med;
    line-height: $token-line-height-loose;
    color: $token-text-color-primary;
}

.usb--option--wrapper {
    position: relative;
    display: flex;
    flex-basis: 100%;
    margin-bottom: $token-spacing-9;
    cursor: pointer;

    @media #{$usb-max-sm} {
        margin-bottom: $token-spacing-6;
    }

    input[type="radio"] {
        opacity: 0;
        position: absolute;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
    }

    input:checked + label {
        background-color: $token-background-brand-primary;
        color: $token-text-color-inverse-primary;

        & .card-select {
            display: flex;
        }

        &.label-card {
            border: $token-border-width-large solid $token-border-interaction;
        }
    }

    input:checked + label > icon {
        color: $token-text-color-inverse-primary;
        fill: $token-text-color-inverse-primary;
    }

    input:focus + label {
        &::after {
            content: "";
            border: $token-border-width-large solid $token-border-focus;
            border-radius: $token-rounded-sm;
            height: calc(100% + #{$token-spacing-3});
            position: absolute;
            left: (-$token-spacing-6px);
            top: (-$token-spacing-6px);
            width: calc(100% + #{$token-spacing-3});
        }
    }

    label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: $token-background-primary;
        border: $token-border-width-default solid $token-border-interaction;
        border-radius: $token-rounded-sm;
        color: $token-text-color-interaction;
        cursor: pointer;
        font-family: $token-font-base;
        -webkit-font-smoothing: antialiased;
        font-size: $token-spacing-14px;
        font-weight: $token-font-weight-reg;
        line-height: $token-spacing-14px;
        height: $token-spacing-9;
        outline: none;
        padding: $token-spacing-3 $token-spacing-6;
        position: relative;
        width: 100%;
        text-align: center;

        &:hover {
            border: $token-border-width-default solid $token-border-interaction;
            background-color: $token-background-brand-interaction;
            color: $token-background-primary;
        }

        & .label-text {
            flex: 1;
        }

        & .label-text-inline {
            max-width: $token-spacing-160px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 auto;
        }
    }

    & .label-left {
        text-align: left;
    }

    & .label-card {
        position: relative;
        min-height: calc(#{$token-spacing-16} * 4);
        max-width: calc(#{$token-spacing-24} * 4);
        min-width: calc(#{$token-spacing-24} * 4);
        margin-bottom: $token-spacing-6;
        padding: 0;
        border: $token-border-width-large solid transparent;
        border-radius: $token-rounded-lg;
        box-shadow: 0 $token-spacing-7px $token-spacing-14px 0 rgba(0, 0, 0, 0.15);
        background-size: cover;
        background-position: center;
        transition: all unquote($token-timing-default-ease);

        > img {
            max-width: calc(#{$token-spacing-16} * 4);
            max-width: calc(#{$token-spacing-24} * 4);
        }

        &:hover {
            border: $token-border-width-large solid $token-border-interaction;
        }

        & .card-image {
            background: url(https://place-hold.it/300x500);
            width: 100%;
            height: 100%;
            background-size: contain;
        }
    }

    & .card-select {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -$token-spacing-2;
        right: -$token-spacing-2;
        background-color: $token-background-brand-primary;
        border-radius: 50%;
        padding: 0 $token-spacing-1 0 0;
    }

    & .usb--option--label--text {
        cursor: pointer;
    }
}
