ul {
  li.disabled {
    display: none !important;
  }

  li.selected {
    color: $token-utility-black !important;
    font-size: $token-font-size-sm;
    font-weight: $token-font-weight-bold;
    line-height: $token-line-height-loose;
  }
}

.usb-pagination.mvp {
  display: flex !important;
}
