@media #{$usb-max-lg} {
  .dropdown {
    &__list {
      width: 100% !important;
    }

    &__list-item-btn p {
      word-break: break-all;
    }
  }
}

.dropdown__btn span.dropdown__btn-selection.selected {
  margin-left: $token-spacing-0 !important;
}

.dropdown__btn.expanded span.dropdown__btn-label,
span.dropdown__btn-label.is-value {
  margin-top: $token-spacing-0 !important;
  font-size: $token-font-size-base !important;
  color: $token-color-grey-70 !important;
}
