@import 'theme/index.scss';

@import '@usb-shield/react-button/dist/library/scss/defaults.scss';

@import '@usb-shield/react-badge/dist/library/styles/scss/index.scss';

@import '@usb-inner-src/react-header-nav/dist/library/styles/scss/index.scss';

@import '@usb-shield/react-grid/dist/library/styles/scss/index.scss';

@import '@usb-shield/react-dropdown/dist/library/styles/scss/defaults.scss';

@import '@usb-shield/react-notification/dist/library/styles/scss/defaults.scss';

@import '@usb-shield/react-grid/dist/library/styles/scss/defaults.scss';

// @import '@usb-inner-src/react-card/dist/library/styles/scss/defaults.scss';

@import '@usb-shield/react-table/dist/library/styles/scss/index.scss';

@import '@usb-shield/react-link/dist/library/styles/scss/defaults.scss';

@import '@usb-shield/react-forms-base/dist/library/styles/scss/defaults.scss';

@import '@usb-shield/react-icons/dist/library/styles/scss/defaults.scss';

@import '@usb-shield/react-tabs/dist/library/styles/scss/index.scss';

// @import '@usb-inner-src/react-message-box/dist/library/styles/scss/index.scss';

// BREADCRUMB
@import '@usb-shield/react-breadcrumb/dist/library/styles/scss/index.scss';

@import '@usb-shield/react-tooltip/dist/library/styles/scss/index.scss';

@import '@usb-inner-src/react-forms-input-password/dist/library/styles/scss/index.scss';

@import '@usb-shield/react-divider-line/dist/library/styles/scss/index.scss';

@import '@usb-shield/react-removable-chips/dist/library/styles/scss/index.scss';

@import '@usb-shield/react-search-input/dist/library/styles/scss/index.scss';

@import '@usb-shield/react-search/dist/library/styles/scss/index.scss';

// USBTableCopy
@import '../common/components/USBTableCopy/styles/index.scss';

@import '@usb-shield/design-tokens/dist/responsive-web/usb-light/usb-light.scss';
@import '@usb-shield/themes/dist/styles/usb-light.css';


@import '@usb-shield/react-menu/dist/library/styles/scss/index.scss';

// import base design tokens from @usb-shield/design-tokens
@import "@usb-shield/design-tokens/dist/responsive-web/usb-light/usb-light.css-adapter.scss";

// import theme variables and mixins from @usb-shield/themes
@import "@usb-shield/themes/dist/library/styles/variables.scss";
@import "@usb-shield/themes/dist/library/styles/helpers/mixins.scss";

// import fonts and reset from @usb-shield/themes
@import "@usb-shield/themes/dist/library/styles/fonts.scss";
@import "@usb-shield/themes/dist/library/styles/reset.scss";

// import peer dependency styles
@import "@usb-shield/react-forms-base/dist/library/styles/scss/defaults.scss";
@import "@usb-shield/react-icons/dist/library/styles/scss/defaults.scss";
@import "@usb-shield/react-chip/dist/library/styles/scss/defaults.scss";

// import component styles from @usb-shield/react-forms-chips
@import "@usb-shield/react-forms-chips/dist/library/styles/scss/defaults.scss";

@import '@usb-shield/react-card/dist/library/styles/scss/index.scss';


// Temporary fix for MFA widget css bug.

html, body {
  font-family: $token-font-base !important;
}

// end MFA temp fix


body {
  font-size: $token-font-size-base;
  line-height: $token-line-height-loose;
  color: $token-color-grey-80;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: $token-line-height-tight;
  color: $token-color-grey-90;
}

h1 {
  font-size: $token-font-size-3xl;
}

h2 {
  font-size: $token-font-size-2xl;
}

p,
.paragraph {
  color: $token-text-color-primary;
}

.text-main {
  font-weight: $token-font-weight-med;
  color: $token-color-grey-90;
}

.page-heading {
  color: $headH2-font-color;
  font-weight: $token-font-weight-bold;
  margin: $token-spacing-4 $token-spacing-0 $token-spacing-6;
}

.content-paf{
  margin-bottom: 30px;
}
button {
  div {
    display: flex;
    justify-content: center;
  }
}

.headH2 {
  @include headH2;
}

.headH3 {
  margin-bottom: $token-spacing-6;
  @include headH3;
}

.paragraph {
  @include paragraph;

  ul {
    @include listUl;
    padding-inline-start: $token-spacing-10;
  }

  p {
    @include paragraphP;
  }
}

// Smaller than LG screen size
@media #{$usb-max-lg} {
  .headH3 {
    margin-bottom: $token-spacing-6;
  }

  .paragraph {
    margin-bottom: $token-spacing-0 !important;

    ul {
      margin-bottom: $token-spacing-0;
    }
  }
}

.stripeEven {
  section:nth-child(even) {
    background-color: $stripe-background-color;
  }
}

.homeStripeOdd {
  section:nth-child(2) {
    background-color: $hsOverviewSection-bgcolor;

    section {
      background-color: $hsOverviewSection-bgcolor;
    }
  }

  section:nth-child(2n+3) {
    background-color: $stripe-background-color;
  }
}

.stripeOdd {
  section:nth-child(odd) {
    background-color: $stripe-background-color;
  }
}

strong {
  font-weight: $token-font-weight-bold !important;
  color: $token-color-grey-90 !important;
}

// USB Message box overrides
.usb-message-box {
  &.userMessage {
    .usb-message-box--message {
      padding-top: $token-spacing-6;
      overflow: inherit !important;

      &.left .avatar {
        background-color: $token-text-color-brand-primary !important;
      }
    }
  }

  &.otherUserMessage {
    .usb-message-box--message {
      padding-top: $token-spacing-6;
      overflow: inherit !important;

      &.left .avatar {
        background-color: $token-color-divider-light-background !important;
        color: $token-text-color-brand-primary !important;
      }
    }
  }
}
.usb-message-box--message {
  margin-bottom: 0.25rem !important;
}
// Tab Container overrides
.customTabContainer {
  .prev-next {
    text-align: right !important;
  }
  .table-sort-header {
    width: 100%;
  }
}

//blockers container
.blockersTabContainer {
  .prev-next {
    text-align: right !important;
  }
  .table-sort-header {
    width: 100%;
  
  }
}

//blockers container
.blockersTabContainer {
  .prev-next {
    text-align: right !important;
  }
  .table-sort-header {
    width: 100%;

  }
}

a {
  &.anchor {
    /** Bias anchor offset so that when clicking an anchor link we scroll below the header */
    position: relative;
    top: $token-spacing-8;

    display: block;
    visibility: hidden;
    overflow: hidden;

    height: $token-spacing-0;

    padding-top: $displayViews-markdown-anchor-padding-top;
    margin-top: $displayViews-markdown-anchor-margin-top;
  }
}

.footnotes h2 {
  display: none;
}

.usb-notification a {
  display: contents
}
.usb-notification > .error .multiple-notification--link-text,.usb-notification > .error a {
  color: $token-text-color-primary;
}
// Tooltip overrides
.tooltip {
  &__content {
    &--dark {
      background-color:  $dark-tooltip-background-color;
      p { color: $dark-tooltip-content-text-color; }
    }
  }
}

.usb-card-section {
  padding: $token-spacing-4;
}


@media #{$usb-max-sm} {
  .markdownTable {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}
.usb-table_pagination {
  width: auto;
}

@media #{$usb-max-sm} {
.usb-table_pagination {
  width: 330px !important;
}
}

@media (min-width: 768px) and (max-width: 1400px) {
  .usb-table_pagination {
    width: 700px !important;
  }
  .usb-table_container {
    overflow-x: scroll;
  }
  
}

.usb-pagination--button {
  button {
    background: none !important;
  }
}
/* Styles for highlight */
@import './custom-highlight.scss';

.error-toast .success-toast-icon {
  background-color: transparent !important;
}
.table-sort__container .usb-button.selected  {
  background-color: rgb(255, 255, 255);
  color: rgb(85, 85, 85);
}

.usb-table .usb-dropdown {
  margin-bottom :  $token-spacing-2 !important;
}
.usb--radio-label{
  margin-top : $token-spacing-0 !important;
}
/* Styles for shortcodes support */
@import './shortcodes/card';
@import './shortcodes/code-block';
@import './shortcodes/notice';
@import './shortcodes/page-section-header-list';
@import './shortcodes/phone-email-card';
@import './shortcodes/column';

/* Styles for global components support */
@import './components/usb-grid';
@import './components/usb-modal';
@import './components/usb-breadcrumb';
@import './components/usb-radio';
@import './components/usb-dropdown';
@import './components/usb-notification';
@import './components/usb-tabs';
@import './components/toast';
@import './components/datepicker';
@import './components/usb-button';
@import './components/react-paginate';
@import './components/usb-tile';
@import './components/usb-react-forms-group-option-button';

/* Styles for region picker support */
@import './regionpicker/regionpicker';

/* Styles for USBTable copy */
@import './components/usb-table-copy';
