.usb-button {
    &.button {
        &--custom-delete {
            background: #{$button-loud-background-color};
            border-color: $button-loud-border-color;
            border-width: $button-loud-border-width;
            border-radius: $button-loud-border-radius;
            color: $button-loud-text-color;
            font-family: $button-loud-font-family;
            -webkit-font-smoothing: antialiased;
            font-weight: $button-loud-font-weight;
            line-height: $button-loud-line-height;

            .svg-icon {
                color: $button-loud-icon-fill-color;
            }

            &:hover {
                background: #{$button-loud-hover-background-color};
                color: $button-loud-hover-text-color;

                .svg-icon {
                    color: $button-loud-hover-icon-fill-color;
                }
            }

            &:active {
                background: #{$button-loud-active-background-color};
                color: $button-loud-active-text-color;

                .svg-icon {
                    color: $button-loud-active-icon-fill-color;
                }
            }

            @include touch-device(#{$button-loud-background-color}, $button-loud-text-color, #{$button-loud-active-background-color}, none, none, #{$button-loud-background-color})
        }

        &--loud {
            background: $edp-button-loud-background-color !important;
        }
    }

    &.selected {
        background-color: $token-color-brand-interaction;
        color: $token-utility-white;

        svg {
            fill: $token-utility-white !important
        }

        &:hover {
            background-color: $token-utility-white;
            color: $token-color-brand-interaction; 

            svg {
                fill: $token-color-brand-interaction !important
            }
        }
    }
}

.usb-button-group .usb-button.button--default:last-child,
.usb-button-group .usb-button.button--large:last-child {
  margin-top: -1rem !important;
}

.usb-button-group .usb-button.button--default:first-child,
.usb-button-group .usb-button.button--large:first-child {
  margin-bottom: -1rem !important;
}

@media (max-width: 672px) {
  .usb-button-group .usb-button.button--default:last-child,
  .usb-button-group .usb-button.button--large:last-child {
    margin-top: $token-spacing-3 !important;
  }

  .usb-button-group .usb-button.button--default:first-child,
  .usb-button-group .usb-button.button--large:first-child {
    margin-bottom: $token-spacing-3 !important;
  }
}
