@mixin bodyLarge($color) {
  // $color can be $grey90 or $grey80 or $grey70 or $white
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-reg;
  font-size: $token-font-size-base;
  line-height: $token-line-height-loose;
}
@mixin bodyMedium($color) {
  // $color can be $grey90 or $grey80 or $grey70 or $white
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-reg;
  font-size: $token-font-size-base;
  line-height: $token-line-height-loose;
}
@mixin bodySmall($color) {
  // $color can be $grey90 or $grey80 or $grey70 or $white
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-reg;
  font-size: $token-font-size-sm;
  line-height: $token-line-height-loose;
}
@mixin superhead($color) {
  // $color can be $grey90 or $grey70 or $white
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-sm;
  line-height: $token-line-height-loose;
}
@mixin caption($color) {
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-reg;
  font-size: $token-font-size-xs;
  line-height: $token-line-height-loose;
}
@mixin disclosure($color) {
  // $color can be $grey70 or $white
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-reg;
  font-size: $token-font-size-xs;
  line-height: $token-line-height-loose;
}
@mixin calloutLarge($color) {
  // $color can be $usb-bank-blue or $grey90 or $white
  color: $grey70;
  font-family: $token-font-callout;
  font-size: $token-font-size-xl;
  line-height: $token-line-height-loose;
}
@mixin calloutSmall($color) {
  // $color can be $usb-bank-blue or $grey90 or $white
  color: $color;
  font-family: $token-font-callout;
  font-size: $token-font-size-lg;
  line-height: $token-line-height-loose;
}
@mixin subheadingLarge($color) {
  // $color can be $usb-bank-blue or $grey90 or $white
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-base;
  line-height: $token-line-height-loose;
}
@mixin subheadingSmall($color) {
  // $color can be $usb-bank-blue or $grey90 or $white
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-sm;
  line-height: $token-line-height-loose;
}

@mixin displayLarge($color) {
  // $color can be $usb-bank-blue or $white
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-bold;
  font-size: $token-font-size-5xl;
  line-height: $token-line-height-tight;
}
@mixin displayMedium($color) {
  // $color can be $usb-bank-blue or $white
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-bold;
  font-size: $token-font-size-4xl;
  line-height: $token-line-height-tight;
}
@mixin displaySmall($color) {
  // $color can be $usb-bank-blue or $white
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-size: $token-font-size-3xl;
  line-height: $token-line-height-tight;
}
@mixin headingLarge($color) {
  // $color can be $grey90 or $white
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-2xl;
  line-height: $token-line-height-tight;
}
@mixin headingMedium($color) {
  // $color can be $grey90 or $white
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-xl;
  line-height: $token-line-height-tight;
}
@mixin headingSmall($color) {
  // $color can be $grey90 or $white
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-med;
  font-size: $token-font-size-lg;
  line-height: $token-line-height-tight;
}
@mixin inlineLink($color) {
  // $color can be $usb-interaction-blue or $white
  color: $color;
  font-family: $token-font-base;
  -webkit-font-smoothing: antialiased;
  font-weight: $token-font-weight-reg;
  font-size: $token-font-size-base;
  line-height: $token-line-height-loose;
  &:hover {
    text-decoration: none;
  }
}

//Fluid Type
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
} // Removes the unit type from the number.

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  //Debugging
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    // Now the good stuff
    & {
      font-size: $min-font-size; // Sets the min-font-size for below 320px screens
      @media screen and (min-width: $token-breakpoint-minimum) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      } // ranges from min to max font size based on screen width below the max breakpoint.
      @media screen and (min-width: $usb-max-screen) {
        font-size: $max-font-size;
      } // sets the max font size for huge screens
    }
  }
}

@mixin notificationBoxShadow($color) {
  box-shadow: 0px 1px 3px $color;
}

@mixin removeHighlight($color) {
  &, & * {
    -webkit-tap-highlight-color: fade-out($color, 1) !important; 
    -webkit-focus-ring-color: fade-out($color, 1) !important; 
    //outline: none !important;
  } 
}
