.sc-card {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: $token-spacing-4;
    padding: $token-spacing-4;
    padding: $token-spacing-4 $token-spacing-4 $token-spacing-4px;
    border-radius: $token-spacing-2;
    border: solid $token-border-width-default $token-border-color-grey;
    background-color: $token-background-primary;
    width: $token-spacing-300px;
    h3{
        padding-top: 0 !important;
    }
    div {
        display: flex;
        margin: 0 0 $token-spacing-12px;
        color: $token-text-color-primary;
        p {
            font-size: $token-font-size-14px;
            line-height: $token-line-height-loose;
        }

        &.card-content{
            flex-direction: column;

            p {
                margin: 0 0 0 0;
                font-weight: $token-font-weight-reg;
                font-size: $token-font-size-base;
            }
            h3{
            line-height: $token-line-height-loose;
            }
        }
    }
}


     