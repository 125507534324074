@mixin shared_region_styles {
    &__group {
        padding: $token-spacing-4 $token-spacing-0 $token-spacing-0 $token-spacing-0 !important;

        .filter__option {
            padding: $token-spacing-3 $token-spacing-4 !important;

            div {
                display: inline-flex;
                align-items: center;
            }
        }
    }
  
    &__group-heading {
        font-size: $token-spacing-4 !important;
        text-transform: capitalize !important;
        color: $token-utility-black !important;
        margin: $token-spacing-0 !important;
    }
  
    &__menu-portal {
        border: $token-border-width-default $text_input-focused-border-style $dropdown-focus-outline-color !important;
    }

    &__menu {
        box-shadow: none !important;
    }

    &__menu-list {
        padding: $token-spacing-0 !important;
        max-height: $token-spacing-500px !important;
        border-radius: $token-spacing-1;
    }
}

.filter {
    &__menu {
        top: $token-spacing-14 !important;
        width: max-content !important;
        border: $token-border-width-default solid $token-color-grey-30;
    }
  
    &__option, &__control {
        background-color: $token-utility-white !important;      
        font-style: $token-letter-spacing-base !important;
        font-weight: $token-font-weight-reg !important;
        font-size: $token-font-size-sm !important;
        border: none !important;

        span {
            margin-left: $token-spacing-2 !important;
            position: relative !important;
            color: $token-color-grey-90 !important;
            font-weight: $token-font-weight-reg !important;
            font-size: $token-font-size-root !important;
            line-height: $token-line-height-30px !important;
        }

        &--is-focused {
            outline: -webkit-focus-ring-color auto $token-spacing-xs !important;
            outline-offset: (-$token-spacing-1);
        }
    }

    &__control {
        span::after {
            content: '';
            border: solid  $token-color-grey-80;
            border-width: $token-spacing-0 $token-spacing-xs $token-spacing-xs 0;
            display: inline-block;
            padding: $token-spacing-1;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            margin: $token-spacing-0 $token-spacing-4px $token-spacing-0 $token-spacing-4;
            position: relative;
            top: -2px;
        }

        &--menu-is-open {
            span::after {
                content: '' !important;
                transform: rotate(225deg) !important;
                -webkit-transform: rotate(225deg) !important;
                position: relative !important;
                top: $token-spacing-1 !important;
            }
        }
    }

    &__single-value div {
        display: inline-flex;
        align-items: center;
    }

    &__group-heading {
        padding: $token-spacing-0 $token-spacing-4 !important;
    }
  
    @include shared_region_styles;
}

.mobileFilter {
    &__menu {
        width: 100% !important;
        border: none;
        margin: $token-spacing-0 !important;
        top: $token-spacing-50px !important;
    }

    &__value-container {
        padding: $token-spacing-0 !important;
    }
  
    &__option, &__control {
        background-color: $token-utility-white !important;      
        font-style: $token-letter-spacing-base !important;
        font-weight: $token-font-weight-reg !important;
        font-size: $token-font-size-sm !important;
        border: none !important;

        span {
            margin-left: $token-spacing-2 !important;
            position: relative !important;
            bottom: $token-spacing-small !important;
            color: $token-color-grey-90 !important;
        }

        &--is-focused {
            outline: -webkit-focus-ring-color auto $token-spacing-xs !important;
            outline-offset: (-$token-spacing-1);
        }
    }

    &__control {
        span::after {
            content: url('/header/chevron-down.svg');
            height: $token-spacing-4;
            background-repeat: no-repeat;
            border: none;
            background-size: 100%;
            text-align: right;
            float: right;
            margin-right: $token-spacing-0;
            transform: scale(1.5);
        }

        &--menu-is-open {
            span::after {
            content: url('/header/chevron-down.svg');
            transform: scale(1.5) rotate(180deg) !important;
            -webkit-transform: scale(1.5) rotate(180deg) !important;
            position: relative !important;
            top: $token-spacing-1 !important;
            }
        }
    }

    &__single-value {
        margin-left: $token-spacing-1;
        margin-right: $token-spacing-0;
    }

    @include shared_region_styles;

    &__group-heading {
        padding: $token-spacing-0 !important;
    }

    &__option {
        padding: $token-spacing-3 $token-spacing-0 !important;

        &--is-focused {
            outline-offset: (-$token-spacing-xs);
        }
    }
}