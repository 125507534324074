.table {
  display: table;
  border: 1px solid $token-border-base;
  border-collapse: collapse;
  min-width: 100%;
  &.auto {
    table-layout: auto;
    tr {
      font-size: $token-font-size-sm;
      color: $token-text-color-secondary;
      font-weight: $token-font-weight-reg;
      line-height: $token-line-height-loose;
      display: table-row;
      &:nth-child(odd) {
        background: $token-background-secondary;
      }
      th {
        font-size: $token-font-size-base;
        font-weight: $token-font-weight-med;
        line-height: $token-line-height-flat;
        vertical-align: top;
        max-width: 250px;
        white-space: normal;
        word-wrap: normal;
        text-align: left;
        border-width: 0px 0px 1px 1px;
        border-style: none none solid solid;
        border-color: transparent transparent $token-border-base
          $token-border-base;
        padding-left: $token-spacing-6;
        padding-right: $token-spacing-6;
        padding-top: $token-spacing-5;
        padding-bottom: $token-spacing-4;
        white-space: nowrap;
        &:first-of-type {
          border-left: 0px none transparent;
        }
        &.table-cell-link {
          border-left: 0px none transparent;
        }
        .usb-button {
          padding-top: $token-spacing-0;
          padding-bottom: $token-spacing-0;
          margin: $token-spacing-0;
        }

        .usb--checkbox-label {
          font-size: $token-font-size-base;
          font-weight: $token-font-weight-med;
        }
      }
      td {
        max-width: 250px;
        text-align: left;
        overflow: hidden;
        overflow-wrap: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-left: 1px solid $token-border-base;
        padding-left: $token-spacing-6;
        padding-right: $token-spacing-6;
        padding-top: $token-spacing-4;
        padding-bottom: $token-spacing-4;
        &:first-of-type {
          border-left: 0px none transparent;
        }
        &.table-cell-link {
          border-left: 0px none transparent;
        }
        &.wrapped {
          white-space: pre-wrap;
        }
        .clamped-cell {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: var(--clamp-value);
          overflow: hidden;
        }
        &.bordered {
          border: 1px solid $token-border-base;
        }
      }
    }
  }
  &.fixed {
    table-layout: fixed;
  }
  &.aem-variant-1 {
    border-radius: $token-spacing-2;
    tr {
      &:nth-child(odd) {
        background: transparent;
      }
      th,
      td {
        border-width: 0px 0px 1px 0px;
        border-style: none none solid none;
        border-color: transparent transparent $token-color-grey-30 transparent;
        &:first-child {
          font-weight: $token-font-weight-med;
        }
        p.up,
        p.down {
          font-weight: $token-font-weight-med;
          padding-left: $token-spacing-4;
          position: relative;
          svg {
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
            polygon,
            circle,
            path,
            rect {
              fill: inherit;
              color: inherit;
            }
          }
          &.up {
            color: #157f4a;
            svg {
              color: #157f4a;
              fill: #157f4a;
            }
          }
          &.down {
            color: $token-color-data-4;
            svg {
              color: $token-color-data-4;
              fill: $token-color-data-4;
            }
          }
        }
        p.subtext {
          @include bodySmall($token-text-color-secondary);
          font-size: $token-font-size-xs;
        }
      }
      th {
        padding: $token-spacing-4 $token-spacing-4 $token-spacing-2;
        @include superhead($token-color-grey-70);
        font-weight: $token-font-weight-med;
        letter-spacing: 1px;
      }
      td {
        padding: $token-spacing-2 $token-spacing-4;
      }
      &:last-of-type {
        td {
          border: 0px none transparent;
          padding: $token-spacing-2 $token-spacing-4 $token-spacing-4;
        }
      }
    }
  }
}
.table-controls {
  display: flex;
  justify-content: space-between;
  padding: $token-spacing-4 0px;
  .prev-next {
    padding: $token-spacing-2 0px;
    color: $token-color-grey-70;
    .usb-button {
      margin: $token-spacing-0;
      padding: $token-spacing-0;
      &:active {
        color: $token-color-grey-90 !important;
        .svg-icon {
          color: inherit !important;
        }
      }
    }
  }

  &.without-row-control {
    justify-content: flex-end;
  }
}
.align-currency {
  text-align: right !important;
}
.caption {
  margin-bottom: 40px;
}
.select-all-responsive {
  display: none;
}
@media (max-width: 47.9375rem) {
  .table {
    width: 100%;
    border: 2px solid #ccc;
    border-collapse: collapse;
  }
  .table th,
  .table td {
    max-width: 100% !important;
    display: block;
    padding: 7px 1rem !important;
    border: 0px !important;
  }
  .table td.responsive-display-none {
    display: none;
  }
  .table td::before {
    content: attr(data-header);
    display: inline-block;
    width: 40%;
    text-align: left;
    white-space: initial;
    color: $token-text-color-primary;
    font-weight: 500;
  }
  .table td.full-width::before {
    content: "";
    display: inline-block;
    width: 0%;
    text-align: center;
  }
  .table th {
    text-align: left;
    background-color: #eee;
    padding: 5px;
    outline: 1px solid #ccc;
  }
  /*.table th.column-header {
    display: none;
  } */
  .table td span.floatRight {
    width: 60%;
    display: inline-block;
    text-align: right;
  }
  .table td.full-width span {
    width: 100%;
    text-align: center;
  }
  .align-currency {
    text-align: left !important;
  }
  .clamped-cell {
    display: inline-block !important;
    width: 60%;
    vertical-align: top;
    text-align: right;
  }
  .select-all-responsive {
    display: inline-block;
    margin: 0 0 1.1rem 1.12rem;
  }
}
